.post-card {
  &__imported {
    width: fit-content !important;
    margin: 0 $spacer * 2;
  }

  &__error {
    background-color: $danger;
  }

  &__media-wrap {
    width: 100%;
    height: 200px;
    margin: $spacer 0 0;

    @include media-breakpoint-up('md') {
      width: 150px;
      height: 150px;
      margin: 0 0 0 $spacer * 2;
    }
  }

  &__content {
    word-break: break-word;
  }
}
