.ghost {
  background-color: $gray-300;
  border-radius: 2px;
  margin-bottom: $ghost-base-height;
  min-height: $ghost-base-height;
}

.ghost-sm {
  min-height: $ghost-base-height / 1.8;
}

.ghost-lg {
  min-height: $ghost-base-height * 1.4;
}
