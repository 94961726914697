@each $color, $value in $grays {
  @include bg-variant('.bg-gray-#{$color}', $value);
}

@each $sm, $value in $sm-colors {
  @include bg-variant('.bg-#{$sm}', $value);
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}
