.tag {
  font-size: $tag-font-size;

  .icon {
    margin-bottom: -1px;
  }

  &__tag-icon {
    margin-right: 0.5em;
  }

  &__remove-icon {
    width: 0.75em;
    height: 0.75em;
    margin-left: 0.5em;
  }
}
