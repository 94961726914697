.avatar {
  width: 100%;
  height: 100%;
  position: relative;

  &-disconnected {
    opacity: 0.3;
  }
}

.avatar-inner {
  padding-right: 10%;
  padding-bottom: 10%;
}

.avatar-image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 50%;
  background-color: $gray-300;
  background-size: cover;
  background-position: center;
}

.avatar-provider-lg {
  .avatar-inner {
    padding-right: 20%;
    padding-bottom: 20%;
  }

  .avatar-provider {
    width: 60%;
    height: 60%;
  }
}

.avatar-provider-md {
  .avatar-provider {
    width: 50%;
    height: 50%;
  }
}

.avatar-provider-sm {
  .avatar-inner {
    padding-right: 0%;
    padding-bottom: 0%;
  }

  .avatar-provider {
    width: 40%;
    height: 40%;
  }
}

.avatar-provider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 2px solid $white;
  border-radius: 50%;
  width: 50%;
  height: 50%;

  .icon {
    width: 80%;
    height: 80%;
  }
}

.avatar-provider-tick {
  background-color: $success;
  border: none;
}

@each $sm, $value in $sm-colors {
  .avatar-provider-#{$sm} {
    background-color: $value;
  }
}
