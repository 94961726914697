// Ray
@each $color, $value in $grays {
  .btn-gray-#{$color} {
    @include button-variant($value, $value);
  }

  .btn-outline-gray-#{$color} {
    @include button-outline-variant($value);
  }
}

.btn-white {
  @include button-variant($white, $white);

  &:hover,
  &:focus {
    background-color: $white;
    border-color: $white;
  }
}

@each $sm, $value in $sm-colors {
  .btn-#{$sm} {
    @include button-variant($value, $value);
  }

  .btn-outline-#{$sm} {
    @include button-variant($value, $value);
  }
}

.btn-twitter {
  color: $white;
}

.btn .icon {
  pointer-events: none;
}

.btn-icon {
  padding: $input-btn-padding-y;
  border-radius: $btn-border-radius;

  &.btn-sm {
    padding: $input-btn-padding-y-sm;
    border-radius: $btn-border-radius-sm;
  }

  &.btn-lg {
    padding: $input-btn-padding-y-lg;
    border-radius: $btn-border-radius-lg;
  }

  .icon {
    margin: 0;
  }

  &-left,
  &-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-left {
    flex-direction: row-reverse;

    .icon {
      margin-right: $spacer;
    }
  }

  &-right {
    .icon {
      margin-left: $spacer;
    }
  }
}

.btn.disabled,
.btn:hover.disabled {
  background-color: $gray-200;
  border-color: $gray-400;
  color: $gray-600;
}
