@mixin icon-variant($color) {
  fill: $color;

  &:not(.disabled):not(.active):hover {
    fill: scale-color($color, $lightness: -55%);
  }
}

@each $color, $value in $theme-colors {
  .icon-#{$color} {
    @include icon-variant($value);
  }
}

@each $color, $value in $grays {
  .icon-gray-#{$color} {
    @include icon-variant($value);
  }
}

.icon-white {
  @include icon-variant($white);
}

.icon-black {
  @include icon-variant($black);
}

@each $sm, $value in $sm-colors {
  .icon-#{$sm} {
    @include icon-variant($value);
  }
}
