.post-media {
  display: grid;
  grid-gap: 1px;
  width: 100%;
  height: 100%;
}

.post-media--empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray-100;
  color: $gray-500;
  font-weight: 500;
  font-size: $h6-font-size;
}

.post-media__image {
  background-size: cover;
  background-position: 50%;
}

.post-media__video {
  width: 100%;
  height: 100%;
}

.post-media--1 {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  .post-media__image:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
}

.post-media--2 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  .post-media__image:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }

  .post-media__image:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }
}

.post-media--3 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  .post-media__image:nth-child(1) {
    grid-column: 1 / 3;
    grid-row: 1;
  }

  .post-media__image:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }

  .post-media__image:nth-child(3) {
    grid-column: 2;
    grid-row: 2;
  }
}

.post-media--4 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  .post-media__image:nth-child(1) {
    grid-column: 1;
    grid-row: 1 / 2;
  }

  .post-media__image:nth-child(2) {
    grid-column: 2;
    grid-row: 1 / 2;
  }

  .post-media__image:nth-child(3) {
    grid-column: 1;
    grid-row: 2 / 3;
  }

  .post-media__image:nth-child(4) {
    grid-column: 2;
    grid-row: 2 / 3;
  }
}
